import { UsageProfileEnum } from '../app/enums/UsageProfile.enum';
import {
  DgnbIndicator,
  MixedUsageProfile,
  PreCheckIndicator,
  Project,
  UsageProfile,
} from '@eeule/eeule-shared/src/types';
import { UsageProfileTypeEnum } from '../app/enums/UsageProfileTypeEnum';
import { sortByKey } from './array-like.helper';
import { CatalogueSumDataByUsageProfile } from '../app/projects/audit/services/audit-subject-context.service';
import { ClpType } from '../app/types/common-types';
import { QueryCondition } from '../app/types/firebase-types';

/**
 * Interface for representing a tree item in the indicator catalogue.
 */
export interface DgnbIndicatorTreeItem extends DgnbIndicator {
  parentId?: string;
  parentName?: string;
  parentIndicator?: DgnbIndicator;
  children?: DgnbIndicatorTreeItem[];
  expandable: boolean;
  isDataElement?: boolean;
  preCheckClpSum?: number; // only on indicator items that are not end notes
  assessmentClpSum?: number; // only on indicator items that are not end notes
  auditClpSum?: number; // only on indicator items that are not end notes
  preCheckBonusSum?: number; // only on indicator items that are not end notes
  assessmentBonusSum?: number; // only on indicator items that are not end notes
  auditBonusSum?: number; // only on indicator items that are not end notes
  bonus?: string | null | undefined;
}

/**
 * Type representing a partial DGNB indicator, a partial DGNB indicator tree item,
 * and an optional catalogue ID.
 */
export type DgnbIndicatorX = Partial<PreCheckIndicator>
  & Partial<DgnbIndicatorTreeItem>
  & { catalogueId?: string | null | undefined };

/**
 * Constructs an indicator by removing certain properties from the given partial indicator object.
 *
 * This function creates a new partial indicator object by copying the properties of the given indicator.
 * It then removes specific properties that are not needed in the constructed indicator.
 *
 * @param {Partial<DgnbIndicatorX>} indicator - The partial indicator object to be processed.
 * @returns {Partial<DgnbIndicator>} A new partial indicator object with the specified properties removed.
 */
export function constructAsIndicator(indicator: Partial<DgnbIndicatorX>): Partial<DgnbIndicator> {
  const _indicator: Partial<DgnbIndicatorX> = { ...indicator };
  Object.keys(_indicator).forEach(key => {
    switch (key) {
      case 'parentId':
      case 'parentName':
      case 'parentIndicator':
      case 'children':
      case 'expandable':
      case 'isDataElement':
      case 'metadata':
      case 'exists':
      case 'createTime':
      case 'updateTime':
        delete _indicator[key as keyof DgnbIndicatorX];
        break;
    }
  });
  return _indicator;
}

/**
 * Generates the CLP sums for a list of indicator tree items.
 *
 * This function recursively calculates the pre-check, assessment, and audit CLP sums for each indicator tree item
 * and its children. It returns a new list of indicator tree items with the calculated sums.
 *
 * @param {DgnbIndicatorTreeItem[]} indicators - The list of indicator tree items.
 * @returns {DgnbIndicatorTreeItem[]} A new list of indicator tree items with the calculated CLP sums.
 */
export function generateClpSumsFromIndicatorTree(indicators: DgnbIndicatorTreeItem[]): DgnbIndicatorTreeItem[] {
  return [...indicators].map(treeItem => {
    const _indicator: DgnbIndicatorTreeItem = { ...treeItem };
    if (!treeItem.children?.length && !treeItem.parentId && treeItem.data) {
      _indicator.preCheckClpSum = treeItem.data.preCheckClp;
      _indicator.assessmentClpSum = treeItem.data.assessmentClp;
      _indicator.auditClpSum = treeItem.data.auditClp;
      return _indicator;
    }
    if (!treeItem.children) return _indicator;
    _indicator.children = generateClpSumsFromIndicatorTree(treeItem.children);
    const childIndicators: DgnbIndicatorTreeItem[] | undefined = _indicator.children;

    const _preCheckClp: number = childIndicators
      .filter(ci => ci.data?.preCheckClp || ci.preCheckClpSum)
      .map(ci => ci.data?.preCheckClp || ci.preCheckClpSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);
    const _assessmentClp: number = childIndicators
      .filter(ci => ci.data?.assessmentClp || ci.assessmentClpSum)
      .map(ci => ci.data?.assessmentClp || ci.assessmentClpSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);
    const _auditClp: number = childIndicators
      .filter(ci => ci.data?.auditClp || ci.auditClpSum)
      .map(ci => ci.data?.auditClp || ci.auditClpSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);

    const _defaultPreCheckBonusSum: number = childIndicators
      .filter(ci => (ci.data?.preCheckClp || ci.preCheckClpSum)
        && (ci.bonus === 'AGENDA2030' || ci.bonus === 'CIRCULAR_ECONOMY'))
      .map(ci => ci.data?.preCheckClp || ci.preCheckClpSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);
    const _additionalPreCheckBonusSum: number = childIndicators
      .filter(ci => (ci.preCheckBonusSum)
        && (ci.bonus !== 'AGENDA2030' && ci.bonus !== 'CIRCULAR_ECONOMY'))
      .map(ci => ci.preCheckBonusSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);
    const _preCheckBonusSum: number = _defaultPreCheckBonusSum + _additionalPreCheckBonusSum;

    const _defaultAssessmentBonusSum: number = childIndicators
      .filter(ci => (ci.data?.assessmentClp || ci.assessmentClpSum)
        && (ci.bonus === 'AGENDA2030' || ci.bonus === 'CIRCULAR_ECONOMY'))
      .map(ci => ci.data?.assessmentClp || ci.assessmentClpSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);
    const _additionalAssessmentBonusSum: number = childIndicators
      .filter(ci => (ci.assessmentBonusSum)
        && (ci.bonus !== 'AGENDA2030' && ci.bonus !== 'CIRCULAR_ECONOMY'))
      .map(ci => ci.assessmentBonusSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);
    const _assessmentBonusSum: number = _defaultAssessmentBonusSum + _additionalAssessmentBonusSum;

    const _defaultAuditBonusSum: number = childIndicators
      .filter(ci => (ci.data?.auditClp || ci.auditClpSum)
        && (ci.bonus === 'AGENDA2030' || ci.bonus === 'CIRCULAR_ECONOMY'))
      .map(ci => ci.data?.auditClp || ci.auditClpSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);
    const _additionalAuditBonusSum: number = childIndicators
      .filter(ci => (ci.auditBonusSum)
        && (ci.bonus !== 'AGENDA2030' && ci.bonus !== 'CIRCULAR_ECONOMY'))
      .map(ci => ci.auditBonusSum || 0)
      .reduce((pv, cv) => pv! + cv!, 0);
    const _auditBonusSum: number = _defaultAuditBonusSum + _additionalAuditBonusSum;

    _indicator.preCheckClpSum = _preCheckClp <= _indicator.maxClp + _preCheckBonusSum
      ? _preCheckClp
      : _indicator.maxClp + _preCheckBonusSum;
    _indicator.assessmentClpSum = _assessmentClp <= _indicator.maxClp + _assessmentBonusSum
      ? _assessmentClp
      : _indicator.maxClp + _assessmentBonusSum;
    _indicator.auditClpSum = _auditClp <= _indicator.maxClp + _auditBonusSum
      ? _auditClp
      : _indicator.maxClp + _auditBonusSum;
    _indicator.preCheckBonusSum = _preCheckBonusSum;
    _indicator.assessmentBonusSum = _assessmentBonusSum;
    _indicator.auditBonusSum = _auditBonusSum;
    return _indicator;
  });
}

/**
 * @Author Christian Sanker
 * Generates a tree structure from a list of indicators.
 * @param indicators - The list of indicators
 * @param bonusMap - bonusMap to determine the bonus of an indicator if present
 * @returns A tree structure of indicators
 */
export function generateIndicatorTree(indicators: DgnbIndicator[], bonusMap: Map<string, string>): DgnbIndicatorTreeItem[] {
  const indicatorMap: Map<string, DgnbIndicatorTreeItem> = new Map<string, DgnbIndicatorTreeItem>();

  // Convert indicators to tree nodes and add them to the map
  indicators.forEach(indicator => {
    indicatorMap.set(indicator.id, {
      ...indicator,
      children: [],
      expandable: !!indicator.indicatorIds && indicator.indicatorIds.length > 0,
      isDataElement: !!indicator.data,
      bonus: indicator.bonusses?.length
        ? bonusMap.get(indicator.bonusses[0]) || null
        : null,
    });
  });

  const rootIndicators: DgnbIndicatorTreeItem[] = [];

  // Function to recursively find and attach children of an indicator
  function findAndAttachChildren(indicator: DgnbIndicatorTreeItem): void {
    if (indicator.indicatorIds && indicator.indicatorIds.length > 0) {
      indicator.indicatorIds?.forEach(childId => {
        const childIndicator = indicatorMap.get(childId);
        if (childIndicator && indicator.children && !indicator.children.find(o => o.id === childIndicator.id)) {
          indicator.children.push({
            ...childIndicator,
            parentId: indicator.id,
            parentName: indicator.name,
            parentIndicator: indicator,
          });
          findAndAttachChildren(childIndicator); // Rekursiver Aufruf
        }
      });
    }
  }

  // Create the tree structure
  indicators.forEach(indicator => {
    const treeItem = indicatorMap.get(indicator.id);
    if (treeItem) {
      findAndAttachChildren(treeItem);
      // Überprüfe, ob der aktuelle Knoten ein Wurzelknoten ist
      const isRoot = indicators.every(parent => {
        return !parent.indicatorIds || !parent.indicatorIds.includes(indicator.id);
      });
      if (isRoot) {
        rootIndicators.push(treeItem);
      }
    }
  });

  return rootIndicators;
}

/**
 * @Author Christian Sanker
 * Retrieves the IDs of all child indicators of a given indicator tree item.
 * @param indicatorTree The indicator tree item.
 * @param referenceIndicators The reference indicators from which to search for children.
 * @returns An array of child indicator IDs.
 */
export function getFlatIndicatorTreeItemChildIds(indicatorTree: DgnbIndicatorTreeItem,
                                                 referenceIndicators: DgnbIndicator[]): string[] {
  const childIds: string[] = [];

  /**
   * Recursively traverses the indicator tree to collect child indicator IDs.
   * @param indicator The current indicator being traversed.
   */
  function traverse(indicator: DgnbIndicator) {
    childIds.push(indicator.id);
    const childIndicators: DgnbIndicator[] = referenceIndicators.filter(o => {
      return indicator.indicatorIds?.some(id => o.id === id);
    });
    childIndicators.forEach(indicator => traverse(indicator));
  }

  const treeItemIndicator: DgnbIndicator | undefined = referenceIndicators.find(o => o.id === indicatorTree.id);
  if (!treeItemIndicator) return [];
  traverse(treeItemIndicator);
  return childIds;
}

/**
 * Retrieves an indicator and all its children as a flat list.
 *
 * This function traverses the indicator tree starting from the given indicator
 * and collects the indicator and all its children into a flat list.
 *
 * @param {Partial<DgnbIndicatorX>} indicator - The partial indicator object to be processed.
 * @returns {Partial<DgnbIndicatorX>[]} A flat list of the indicator and its children.
 */
export function getIndicatorWithChildrenAsFlatList(indicator: Partial<DgnbIndicatorX>): Partial<DgnbIndicatorX>[] {
  const flatIndicators: Partial<DgnbIndicatorX>[] = [];

  /**
   * Recursively traverses the indicator tree to collect the indicator and its children.
   *
   * @param {Partial<DgnbIndicatorX>} _indicator - The current indicator being traversed.
   */
  function traverse(_indicator: Partial<DgnbIndicatorX>) {
    flatIndicators.push(_indicator);
    _indicator.children?.forEach(ci => traverse(ci));
  }

  traverse(indicator);
  return flatIndicators;
}

/**
 * Retrieves a flat list of indicators from a tree structure of indicator tree items.
 *
 * This function traverses the indicator tree starting from the given indicator tree items
 * and collects each indicator into a flat list.
 *
 * @param {DgnbIndicatorTreeItem[]} indicators - The list of indicator tree items.
 * @returns {DgnbIndicatorX[]} A flat list of indicators.
 */
export function getIndicatorTreeItemsAsFlatIndicatorList(indicators: DgnbIndicatorTreeItem[]): DgnbIndicatorX[] {
  const flatIndicators: DgnbIndicatorX[] = [];

  /**
   * Recursively traverses the indicator tree to collect indicators.
   *
   * @param {DgnbIndicatorTreeItem} indicator - The current indicator tree item being traversed.
   */
  function traverse(indicator: DgnbIndicatorTreeItem) {
    flatIndicators.push(indicator);
    indicator.children?.forEach(ci => traverse(ci));
  }

  indicators.forEach(indicator => traverse(indicator));
  return flatIndicators;
}

/**
 * Maps an array of LPH (Leistungsphasen) numbers to a comma-separated string.
 *
 * This function converts each number in the array to a string and joins them with a comma and space.
 *
 * @param {number[]} lph - The array of LPH numbers.
 * @returns {string} A comma-separated string of LPH numbers.
 */
export function mapLph(lph: number[]): string {
  return lph.map(n => n.toString()).reduce((a, v) => a + ', ' + v);
}

/**
 * Maps an array of indicator usage profile IDs to a comma-separated string of usage profile names.
 *
 * This function converts each usage profile ID in the array to its corresponding name
 * and joins them with a comma and space.
 *
 * @param {string[]} ids - The array of usage profile IDs.
 * @param {UsageProfile[]} allUsageProfiles - The list of all usage profiles.
 * @returns {string} A comma-separated string of usage profile names.
 */
export function mapIndicatorUsageProfileIds(ids: string[], allUsageProfiles: UsageProfile[]): string {
  let returnString: string = '';
  const usageProfilesMap: Map<string, string> = new Map(allUsageProfiles.map(o => {
    return [o.id, o.name as UsageProfileEnum];
  }));
  for (let i = 0; i < ids.length; i++) {
    if (!usageProfilesMap.get(ids[i])?.length) continue;
    returnString += UsageProfileEnum[usageProfilesMap.get(ids[i])! as keyof typeof UsageProfileEnum];
    if (i < ids.length - 1) returnString += ', ';
  }

  return returnString;
}

/**
 * Filters the given list of indicators based on the project properties.
 *
 * This function iterates through the list of indicators and applies various filtering criteria
 * based on the properties of the project. It returns a list of indicators that match the criteria.
 *
 * @param {PreCheckIndicator[]} indicators - The list of indicators to filter.
 * @param {Project} project - The project containing properties to filter the indicators.
 * @returns {PreCheckIndicator[]} A list of filtered indicators.
 */
export function filterIndicatorsByProjectProperties(indicators: PreCheckIndicator[], project: Project): PreCheckIndicator[] {
  const _indicators: PreCheckIndicator[] = [];
  for (const indicator of indicators || []) {
    // Skip if the indicator has no id
    if (!indicator?.id) continue;

    // Skip if the project requires circular economy or deconstruction and the indicator does not support it
    if ((project.circularEconomy || project.withDeconstruction) && !indicator.circularEconomy) continue;

    // Skip if the project requires non-circular economy or no deconstruction and the indicator does not support it
    if ((project.nonCircularEconomy || project.withoutDeconstruction) && !indicator.nonCircularEconomy) continue;

    // Skip if the project requires BGF bigger than 5000 and the indicator does not support it
    if (project.bgfBigger5000 && !indicator.bgfBigger5000) continue;

    // Skip if the project requires BGF smaller than 5000 and the indicator does not support it
    if (project.bgfSmaller5000 && !indicator.bgfSmaller5000) continue;

    // Find the project's usage profile that matches the indicator's usage profiles
    const projectUsageProfile: MixedUsageProfile | undefined = project.usageProfiles.find(o =>
      indicator.usageProfiles.some(up => {
        return up === o.usageProfile;
      }),
    );

    // Skip if no matching usage profile is found
    if (!projectUsageProfile) continue;

    // Skip if the indicator's usage profile type is 'subordinateUsage'
    if (projectUsageProfile.usageProfileType === 'subordinateUsage') continue;

    // deprecated - Skip if the indicator's mixed usage profile type is 'NU' and the project's usage profile is not the main usage
    if (indicator.mixedUsageProfileType === ('NU' as UsageProfileTypeEnum) && !projectUsageProfile.isMainUsage) continue;

    // deprecated - Skip if the indicator's mixed usage profile type is 'HN' and the project's usage profile is not the main usage
    if (indicator.mixedUsageProfileType === ('HN' as UsageProfileTypeEnum) && !projectUsageProfile.isMainUsage) continue;

    // Skip if the indicator's mixed usage profile type is 'NU' and the project's usage profile is not the main usage
    if (indicator.mixedUsageProfileType === ('NU' as UsageProfileTypeEnum) && projectUsageProfile.usageProfileType === 'sideUsage') continue;

    // Skip if the indicator's mixed usage profile type is 'HN' and the project's usage profile is not the main usage
    if (indicator.mixedUsageProfileType === ('HN' as UsageProfileTypeEnum) && projectUsageProfile.usageProfileType === 'sideUsage') continue;

    // Add the indicator to the filtered list
    _indicators.unshift(indicator);
  }

  return _indicators;
}

/**
 * Calculates the maximum CLP (Credit Limit Points) for a catalogue considering additional or variable points.
 *
 * This function filters the indicators to find those with additional or variable points applied.
 * It then calculates the sum of additional points and subtracts the sum of variable points from the catalogue's maximum CLP.
 *
 * @param {PreCheckIndicator[]} indicators - The list of indicators to be processed.
 * @param {number} catalogueMaxClp - The catalogue for which the maximum CLP is calculated.
 * @returns {number} The calculated maximum CLP for the catalogue.
 */
export function getCatalogueMaxClp(indicators: PreCheckIndicator[],
                                   catalogueMaxClp?: number): number {
  const additionalOrVariablePointsIndicators: PreCheckIndicator[] = indicators.filter(indicator => indicator.data?.additionalOrVariablePointsAreApplied);
  if (!additionalOrVariablePointsIndicators.length) return catalogueMaxClp || 0;
  const additionalSum: number =
    additionalOrVariablePointsIndicators
      .filter(o => o.data?.pointsAreAdditional)
      .map(o => o.maxClp)
      .reduce((pv, cv) => pv! + cv!, 0) || 0;
  const variableSum: number =
    additionalOrVariablePointsIndicators
      .filter(o => o.data?.pointsAreVariable)
      .map(o => o.maxClp)
      .reduce((pv, cv) => pv! + cv!, 0) || 0;
  return (catalogueMaxClp || 0) + additionalSum - variableSum;
}

/**
 * Calculates the maximum CLP (Credit Limit Points) for indicators, considering additional or variable points.
 *
 * This function sorts the indicators by their 'number' property and then maps each indicator to a new object
 * with the calculated maximum CLP. If an indicator has no child indicators and does not have variable points
 * or additional points applied, it is returned as is. If an indicator has no child indicators but has variable
 * points or additional points applied, its maximum CLP is set to 0. For indicators with child indicators,
 * the function subtracts the points of variable points indicators from the maximum CLP.
 *
 * @param {PreCheckIndicator[]} indicators - The list of indicators to process.
 * @returns {PreCheckIndicator[]} A new list of indicators with the calculated maximum CLP.
 */
export function calculateIndicatorsAdditionalOrVariablePointsMaxClp(indicators: PreCheckIndicator[]): PreCheckIndicator[] {
  // Sort indicators by their 'number' property
  return sortByKey(indicators, 'number').map(o => {
    // If the indicator has no child indicators and no variable or additional points, return it as is
    if (!o.indicatorIds?.length && (!o.data?.pointsAreVariable || !o.data.additionalOrVariablePointsAreApplied)) return o;

    // If the indicator has no child indicators but has variable points that are applied, set its maxClp to 0
    if (!o.indicatorIds?.length) return { ...o, maxClp: 0 };

    // Filter indicators that have variable points and additional or variable points applied
    const variablePointsIndicators: PreCheckIndicator[] = indicators.filter(
      indicator => {
        return indicator.data?.pointsAreVariable
          && indicator.data.additionalOrVariablePointsAreApplied
          && o.indicatorIds?.some(id => id === indicator.id)
      },
    );

    // If no variable points indicators are found, return the indicator as is
    if (!variablePointsIndicators.length) return o;

    // Calculate the total points to subtract from the maxClp
    const pointsToSubtract: number = variablePointsIndicators.map(indicator => indicator.maxClp).reduce((pv, cv) => pv! + cv!, 0);

    // Calculate the new maxClp, ensuring it does not go below 0
    const _maxClp: number = o.maxClp - pointsToSubtract >= 0 ? o.maxClp - pointsToSubtract : 0;

    // Return the updated indicator with the new maxClp
    return {
      ...o,
      maxClp: _maxClp,
    };
  });
}

/**
 * Calculates the catalogue sums for each usage profile.
 *
 * This function filters the indicators to find those that match the given usage profile,
 * and then calculates the sum of their CLP (Credit Limit Points) for preCheck, assessment, and audit.
 * It also retrieves the adgnbValue (percentage) for each usage profile.
 *
 * @param {DgnbIndicatorX[]} indicatorsWithClpSums - The list of indicators with CLP sums.
 * @param {number} catalogueMaxClp - The maximum CLP for the catalogue.
 * @param {Map<string, string>} usageProfileMap - The map of usage profile IDs to their corresponding keys.
 * @param {Map<string, Partial<MixedUsageProfile>>} mixedUsageProfileMap - The map of usage profile IDs to their corresponding mixed usage profiles.
 * @returns {CatalogueSumDataByUsageProfile[]} The calculated catalogue sums for each usage profile.
 */
export function getCatalogueClpSumsByUsageProfile(indicatorsWithClpSums: DgnbIndicatorX[],
                                                  catalogueMaxClp: number,
                                                  usageProfileMap: Map<string, string>,
                                                  mixedUsageProfileMap: Map<string, Partial<MixedUsageProfile>>): CatalogueSumDataByUsageProfile[] {
  const usageProfileSet: Set<string> = new Set<string>(indicatorsWithClpSums
    .filter(o => o.usageProfiles?.length)
    .map(o => o.usageProfiles![0])
    .filter(s => s.length));

  const subordinateUsageProfiles: Partial<MixedUsageProfile>[] = Array.from(mixedUsageProfileMap.values())
    .filter(up => up.usageProfileType === 'subordinateUsage');

  const subordinateUsageProfilesAccPercentage = subordinateUsageProfiles
    .reduce((pv, cv) => pv + (cv.percentage || 0), 0);

  return Array.from(usageProfileSet).map(up => {
    const mixedUsageProfileType: UsageProfileTypeEnum | null = indicatorsWithClpSums?.[0]?.mixedUsageProfileType || null;
    const resolvedUp: Partial<MixedUsageProfile> | undefined = mixedUsageProfileMap.get(up);
    const upPercentage: number = resolvedUp?.percentage || 0;
    const adgnbValue: number = mixedUsageProfileType as string === 'SP' && resolvedUp?.usageProfileType === 'mainUsage'
      ? upPercentage + subordinateUsageProfilesAccPercentage
      : mixedUsageProfileType as string === 'SP'
        ? upPercentage
        : 100;

    return {
      usageProfileId: up,
      usageProfileKey: usageProfileMap.get(up) as UsageProfileEnum || null,
      mixedUsageProfileType: mixedUsageProfileType,
      preCheckCatalogueSum: calculateCatalogueSum(indicatorsWithClpSums, up, 'preCheck', catalogueMaxClp),
      assessmentCatalogueSum: calculateCatalogueSum(indicatorsWithClpSums, up, 'assessment', catalogueMaxClp),
      auditCatalogueSum: calculateCatalogueSum(indicatorsWithClpSums, up, 'audit', catalogueMaxClp),
      adgnbValue: adgnbValue,
      catalogueMaxClp: catalogueMaxClp,
      shareValue: indicatorsWithClpSums.filter(o => (
        o.usageProfiles?.length && o.usageProfiles![0] === up
        && o.data
        && o.shareValue
      ))?.[0]?.shareValue || 0,
    };
  });
}

/**
 * Calculates the catalogue sum for a given usage profile and sum type.
 *
 * This function filters the indicators to find those that match the given usage profile
 * and sum type, and then calculates the sum of their CLP (Credit Limit Points).
 * It also calculates the sum of bonus points and ensures that the final sum does not
 * exceed the maximum CLP for the catalogue.
 *
 * @param {DgnbIndicatorX[]} indicatorsWithClpSums - The list of indicators with CLP sums.
 * @param {UsageProfileEnum} usageProfileId - The usage profile id to filter the indicators.
 * @param {ClpType} sumType - The type of sum to calculate (e.g., 'preCheck', 'assessment', 'audit').
 * @param {number} catalogueMaxClp - catalogueMaxClp to determine the maximum CLP for the catalogue.
 * @returns {number} The calculated catalogue sum for the given usage profile and sum type.
 */
export function calculateCatalogueSum(indicatorsWithClpSums: DgnbIndicatorX[],
                                      usageProfileId: string,
                                      sumType: ClpType,
                                      catalogueMaxClp: number): number {
  const unrestrictedSum: number = indicatorsWithClpSums
    .filter(o => o.usageProfiles?.length
      && o.usageProfiles![0] === usageProfileId
      && o[`${sumType}ClpSum`]
      && !o.parentId,
    )
    .map(o => o[`${sumType}ClpSum`]!)
    .reduce((pv, cv) => pv! + cv!, 0);
  const bonusSum: number = indicatorsWithClpSums
    .filter(o => o[`${sumType}BonusSum`] && !o.parentId)
    .map(o => o[`${sumType}BonusSum`]!)
    .reduce((a, v) => a! + v!, 0);
  return unrestrictedSum > catalogueMaxClp + bonusSum
    ? catalogueMaxClp + bonusSum
    : unrestrictedSum;
}

/**
 * Generates query conditions for project indicators based on the project's usage profiles.
 *
 * This function constructs an array of query conditions to filter indicators in Firestore
 * based on the usage profiles associated with the given project. If the project has mixed usage profiles,
 * it includes all usage profiles. Otherwise, it includes only the main usage profiles.
 *
 * @param {Project} project - The project object containing usage profiles.
 * @returns {QueryCondition[]} An array of query conditions for filtering project indicators.
 */
export function generateProjectIndicatorQueryConditions(project: Project): QueryCondition[] {
  const usageProfiles: string[] = project?.hasMixedUsageProfiles
    ? project?.usageProfiles.map(up => up.usageProfile) || []
    : project?.usageProfiles.filter(up => up.isMainUsage || up.usageProfileType === 'mainUsage') // includes deprecated comparison
    .map(up => up.usageProfile) || [];
  return [['usageProfiles', 'array-contains-any', usageProfiles]];
}

export function retrieveCatalogueSubjectSumValueByCatalogueUsageProfileSumData(sumData: CatalogueSumDataByUsageProfile,
                                                                               subjectPercentage: number,
                                                                               catalogueMaxClp: number,
                                                                               scenarioType: 'preCheck' | 'audit'): number | null {
  if (!sumData.usageProfileKey) return null;
  // Determine the sum data to accumulate based on the scenario type
  const sumDataToAccumulate = scenarioType === 'preCheck' ? sumData.preCheckCatalogueSum : sumData.assessmentCatalogueSum;
  // Determine the adgnbValue based on the mixed usage profile type
  // If the mixed usage profile type is 'SP', use the sumData.adgnbValue
  // Otherwise, default to 100
  const adgnbValue = sumData.mixedUsageProfileType === 'SP' ? sumData.adgnbValue : 100;

  // Calculate the subject share value
  const subjectShareValue = ((sumData.shareValue || 0) / (subjectPercentage || 1)) * 100;

  // Calculate the subject value
  const subjectValueCalculated = (subjectShareValue * sumDataToAccumulate) / catalogueMaxClp;

  // Calculate and return the sum value
  return (adgnbValue / 100) * subjectValueCalculated;
}

/**
 * Retrieves the calculated usage profile percentage for a given indicator.
 *
 * This function calculates the percentage of the usage profile based on the indicator's mixed usage profile type
 * and its associated usage profiles. It considers both main and subordinate usage profiles to determine the final percentage.
 *
 * @param {DgnbIndicatorX} indicator - The indicator object containing usage profile information.
 * @param {Map<string, Partial<MixedUsageProfile>>} mixedUsageProfileMap - A map of mixed usage profiles.
 * @returns {number} The calculated usage profile percentage.
 */
export function retrieveCalculatedUsageProfilePercentage(indicator: DgnbIndicatorX,
                                                         mixedUsageProfileMap: Map<string, Partial<MixedUsageProfile>>): number {
  if (indicator.mixedUsageProfileType as string !== 'SP') return 100;
  if (!indicator.usageProfiles?.length) return 0;

  const resolvedUp: Partial<MixedUsageProfile> | undefined = mixedUsageProfileMap.get(indicator.usageProfiles[0]);
  if (!resolvedUp) return 0;

  const subordinateUsageProfiles: Partial<MixedUsageProfile>[] = Array.from(mixedUsageProfileMap.values())
    .filter(up => up.usageProfileType === 'subordinateUsage');

  const subordinateUsageProfilesAccPercentage = subordinateUsageProfiles
    .reduce((pv, cv) => pv + (cv.percentage || 0), 0);


  if (resolvedUp.usageProfileType === 'mainUsage') {
    return (resolvedUp.percentage || 0) + subordinateUsageProfilesAccPercentage;
  }

  return resolvedUp.percentage || 0;
}

/**
 * Retrieves the calculated usage profile percentage for a given indicator.
 * @param indicators
 */
export function getIndicatorStatusMap(indicators: DgnbIndicatorX[]): Map<string, DgnbIndicatorX[]> {
  const statusMap: Map<string, DgnbIndicatorX[]> = new Map<string, DgnbIndicatorX[]>();

  // Iterate through each indicator and group them by their status
  for (const indicator of indicators) {
    let status: string | undefined = indicator.data?.indicatorStatus;
    if (!status) status = 'INACTIVE';
    if (!statusMap.has(status)) {
      statusMap.set(status, []);
    }
    statusMap.get(status)?.push(indicator);
  }

  return statusMap;
}
