import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { ScenarioCollectionType } from '../../types/common-types';
import { combineLatest, forkJoin, from, map, Observable, of, Subject, switchMap } from 'rxjs';
import {
  Action,
  DgnbCriteriaGroup,
  DgnbIndicatorCatalogue, DgnbSystem,
  MixedUsageProfile,
  PreCheckIndicator,
  PreCheckScenario,
  Project,
} from '@eeule/eeule-shared/src/types';
import { FirebaseService } from './firebase.service';
import { DgnbSubjectEnum } from '../../enums/DgnbSubject.enum';
import { doc, DocumentReference, getDoc } from 'firebase/firestore';
import {
  DgnbIndicatorTreeItem,
  DgnbIndicatorX,
  filterIndicatorsByProjectProperties,
  generateClpSumsFromIndicatorTree,
  generateIndicatorTree,
  generateProjectIndicatorQueryConditions,
  getCatalogueClpSumsByUsageProfile,
  getCatalogueMaxClp,
  getIndicatorTreeItemsAsFlatIndicatorList,
} from '../../../util/indicator.helper';
import { QueryCondition } from '../../types/firebase-types';
import { Bonus, UsageProfile } from '@eeule/eeule-shared/src';
import { UsageProfileEnum } from '../../enums/UsageProfile.enum';
import { BonusEnum } from '../../enums/Bonus.enum';
import { createFilteredMap, sortByKey } from '../../../util/array-like.helper';
import {
  CatalogueSumDataByUsageProfile,
  IndicatorCatalogueValues,
} from '../../projects/audit/services/audit-subject-context.service';
import { IndicatorService } from './indicator.service';

export type DgnbIndicatorCatalogueWithSubCollections = DgnbIndicatorCatalogue & {
  groupId?: string,
  indicators?: DgnbIndicatorX[]
};

export type DgnbCriteriaGroupWithSubCollections = DgnbCriteriaGroup & {
  subject?: string,
  catalogues?: DgnbIndicatorCatalogueWithSubCollections[]
};

export type ScenarioWithSubCollections = PreCheckScenario & {
  subjectGroups?: Map<string, DgnbCriteriaGroupWithSubCollections[]>
};

export type SubjectIndicatorCatalogueValuesMap = Map<string, IndicatorCatalogueValues[]>;

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  /**
   * State for handling usage profiles dependent actions and values like Select Options
   * @private
   */
  private allUsageProfiles: WritableSignal<UsageProfile[]> = signal<UsageProfile[]>([]);
  readonly allUsageProfilesSig: Signal<UsageProfile[]> = this.allUsageProfiles.asReadonly();
  private allUsageProfilesMap: WritableSignal<Map<string, string>> = signal<Map<string, string>>(new Map());
  readonly allUsageProfilesMapSig: Signal<Map<string, string>> = this.allUsageProfilesMap.asReadonly();

  /**
   * State for managing aDgnbValues, provided by usageProfiles of a project
   * @private
   */
  private projectMixedUsageProfilesMap: WritableSignal<Map<string, Partial<MixedUsageProfile>>>
    = signal<Map<string, Partial<MixedUsageProfile>>>(new Map());
  readonly projectMixedUsageProfileMapSig: Signal<Map<string, Partial<MixedUsageProfile>>>
    = this.projectMixedUsageProfilesMap.asReadonly();

  /**
   * State for handling bones dependent actions and values like Select Options
   * @private
   */
  private allBonuses: WritableSignal<Bonus[]> = signal<Bonus[]>([]);
  readonly allBonusesSig: Signal<Bonus[]> = this.allBonuses.asReadonly();
  readonly allBonusesMap: WritableSignal<Map<string, string>> = signal<Map<string, string>>(new Map());
  readonly allBonusesMapSig: Signal<Map<string, string>> = this.allBonusesMap.asReadonly();

  /**
   * Indicator Catalogue values for necessary calculations
   * @private
   */
  private subjectIndicatorCatalogueValuesMap: WritableSignal<SubjectIndicatorCatalogueValuesMap> = signal<SubjectIndicatorCatalogueValuesMap>(new Map());
  readonly subjectIndicatorCatalogueValuesMapSig: Signal<SubjectIndicatorCatalogueValuesMap> = this.subjectIndicatorCatalogueValuesMap.asReadonly();

  private fulfillmentDataRefetchTrigger: Subject<void> = new Subject<void>();
  readonly fulfillmentDataRefetchTrigger$: Observable<void> = this.fulfillmentDataRefetchTrigger.asObservable();

  constructor(
    private _firebaseService: FirebaseService,
    private _indicatorService: IndicatorService,
  ) {

  }

  /**
   * Retrieves scenario data with processed sub-collections for fulfillment calculation.
   *
   * This function retrieves the scenario document from Firestore, processes its sub-collections,
   * and returns an observable that emits the scenario with its associated subject groups and sub-collections.
   *
   * @param {Project} project - The project object containing project details.
   * @param {string} scenarioId - The ID of the scenario.
   * @param {ScenarioCollectionType} [collectionType='preCheckScenarios'] - The type of scenario collection.
   * @param {string[]} [subjects] - Optional array of subjects. If not provided, all subjects from DgnbSubjectEnum are used.
   * @returns {Observable<ScenarioWithSubCollections>} An observable that emits the scenario with its associated subject groups and sub-collections.
   * @throws {Error} If the project or bonuses are not available.
   */
  public getSzenarioDataWithProcessedSubCollectionsForFulfillmentCalculation(
    project: Project,
    scenarioId: string,
    collectionType: ScenarioCollectionType = 'preCheckScenarios',
    subjects?: string[],
  ): Observable<ScenarioWithSubCollections> {
    if (!project || !this.allBonusesMapSig()?.size) {
      throw new Error('an error occurred while retrieving necessary dependencies (project, bonuses).');
    }
    const path: string = `projects/${project.id}/${collectionType}`;
    const scenarioDocRef: DocumentReference = doc(this._firebaseService.firestore, path, scenarioId);
    return from(getDoc(scenarioDocRef)).pipe(
      switchMap((snapshot) => {
        const scenario: PreCheckScenario = { ...snapshot.data(), id: snapshot.id } as PreCheckScenario;
        return this.getSubjectGroupsMap(project, scenarioId, collectionType, subjects).pipe(
          map(subjectGroups => ({ ...scenario, subjectGroups })),
        );
      }),
    );
  }

  /**
   * Retrieves processed scenario data and DGNB system information.
   *
   * This function checks if the project, scenario ID, and scenario collection type are provided.
   * If any of these are missing, it returns an observable that emits null values.
   * Otherwise, it retrieves the DGNB system and scenario data with processed sub-collections
   * for fulfillment calculation and returns them as a combined observable.
   *
   * @param {Project | null} [project] - The project object containing project details.
   * @param {string | null} [scenarioId] - The ID of the scenario.
   * @param {ScenarioCollectionType} [scenarioCollectionType] - The type of scenario collection.
   * @returns {Observable<[DgnbSystem | null, ScenarioWithSubCollections | null]>} An observable that emits the DGNB system and scenario data.
   */
  getProcessedScenarioDataAndDgnbSystem(
    project?: Project | null,
    scenarioId?: string | null,
    scenarioCollectionType?: ScenarioCollectionType,
  ): Observable<[DgnbSystem | null, ScenarioWithSubCollections | null]> {
    if (!project?.dgnbSystem || !scenarioId || !scenarioCollectionType) {
      return combineLatest([of(null), of(null)]);
    }

    return this._indicatorService.getDgnbSystem(project.dgnbSystem).pipe(
      switchMap(dgnbSystem => {
        const subjects = dgnbSystem.subjectValues?.map(value => value.subject) || [];
        const scenarioData$ = this.getSzenarioDataWithProcessedSubCollectionsForFulfillmentCalculation(
          project, scenarioId, scenarioCollectionType, subjects,
        );
        return combineLatest([of(dgnbSystem), scenarioData$]);
      }),
    );
  }

  /**
   * Retrieves a map of subject groups with their associated criteria groups and sub-collections from Firestore.
   *
   * This function constructs the path to the criteria groups collection for each subject,
   * retrieves the criteria groups from Firestore, and for each criteria group, retrieves and processes the associated catalogues.
   * It returns an observable that emits a map where keys are subjects and values are lists of criteria groups with their sub-collections.
   *
   * @param {Project} project - The project object containing project details.
   * @param {string} scenarioId - The ID of the scenario.
   * @param {ScenarioCollectionType} collectionType - The type of scenario collection. Defaults to 'preCheckScenarios'.
   * @param {string[]} [subjects] - Optional array of subjects. If not provided, all subjects from DgnbSubjectEnum are used.
   * @returns {Observable<Map<string, DgnbCriteriaGroupWithSubCollections[]>>} An observable that emits the map of subject groups with their criteria groups and sub-collections.
   */
  private getSubjectGroupsMap(project: Project,
                              scenarioId: string,
                              collectionType: ScenarioCollectionType = 'preCheckScenarios',
                              subjects?: string[]): Observable<Map<string, DgnbCriteriaGroupWithSubCollections[]>> {
    const _subjects: string[] = subjects || Object.keys(DgnbSubjectEnum);
    const groups$: Observable<DgnbCriteriaGroupWithSubCollections[]>[] = _subjects.map(subject => {
      return this.getSubjectCriteriaGroupsWithSubCollections(project, scenarioId, subject.toLowerCase(), collectionType);
    });
    return forkJoin(groups$).pipe(
      map(groupsArr => {
        const subjectGroups = new Map<string, DgnbCriteriaGroupWithSubCollections[]>();
        groupsArr.forEach((groups, index) => {
          if (groups.length) {
            subjectGroups.set(_subjects[index], groups);
          }
        });
        return subjectGroups;
      }),
    );
  }

  /**
   * Retrieves criteria groups with their associated sub-collections from Firestore.
   *
   * This function constructs the path to the criteria groups collection within the specified subject,
   * retrieves the criteria groups from Firestore, and for each criteria group, retrieves and processes the associated catalogues.
   * It returns an observable that emits the list of criteria groups with their sub-collections.
   *
   * @param {Project} project - The project object containing project details.
   * @param {string} scenarioId - The ID of the scenario.
   * @param {string} subject - The subject of the criteria groups.
   * @param {ScenarioCollectionType} collectionType - The type of scenario collection.
   * @returns {Observable<DgnbCriteriaGroupWithSubCollections[]>} An observable that emits the list of criteria groups with their sub-collections.
   */
  private getSubjectCriteriaGroupsWithSubCollections(project: Project,
                                                     scenarioId: string,
                                                     subject: string,
                                                     collectionType: ScenarioCollectionType = 'preCheckScenarios')
    : Observable<DgnbCriteriaGroupWithSubCollections[]> {
    const path: string = `projects/${project.id}/${collectionType}/${scenarioId}/${subject}`;

    return this._firebaseService.getCollectionData<DgnbCriteriaGroup>(path,
      null,
      [['name', 'asc']],
      true).pipe(
      switchMap(criteriaGroupsRes => {
        if (!criteriaGroupsRes?.data) return of([]);
        const criteriaGroups$ = criteriaGroupsRes.data.map(cg => {
          const criteriaGroup = { ...cg as DgnbCriteriaGroupWithSubCollections, id: cg.id, subject: subject };
          return this.getCataloguesWithIndicators(project, scenarioId, subject, cg.id, collectionType).pipe(
            map(catalogues => ({ ...criteriaGroup, catalogues })),
          );
        });
        return forkJoin(criteriaGroups$);
      }),
    );
  }

  /**
   * Retrieves catalogues with their associated indicators from Firestore.
   *
   * This function constructs the path to the catalogues collection within the specified criteria group,
   * retrieves the catalogues from Firestore, and for each catalogue, retrieves and processes the associated indicators.
   * It returns an observable that emits the list of catalogues with their indicators.
   *
   * @param {Project} project - The project object containing project details.
   * @param {string} scenarioId - The ID of the scenario.
   * @param {string} subject - The subject of the indicators.
   * @param {string} criteriaGroupId - The ID of the criteria group.
   * @param {ScenarioCollectionType} collectionType - The type of scenario collection.
   * @returns {Observable<DgnbIndicatorCatalogueWithSubCollections[]>} An observable that emits the list of catalogues with their indicators.
   */
  private getCataloguesWithIndicators(project: Project,
                                      scenarioId: string,
                                      subject: string,
                                      criteriaGroupId: string,
                                      collectionType: ScenarioCollectionType)
    : Observable<DgnbIndicatorCatalogueWithSubCollections[]> {
    const path: string = `projects/${project.id}/${collectionType}/${scenarioId}/${subject}/${criteriaGroupId}/catalogues`;
    return this._firebaseService.getCollectionData<DgnbIndicatorCatalogue[]>(
      path,
      null,
      [['name', 'asc']],
      true,
    ).pipe(
      switchMap(catalogueRes => {
        if (!catalogueRes.data) return of([]);
        const catalogues$ = catalogueRes.data.map(catalogue => {
          if (!catalogue.id) return [];
          return this.getFilteredIndicatorsWithSums(project, scenarioId, subject, criteriaGroupId, catalogue.id, collectionType).pipe(
            map(indicators => ({ ...catalogue, indicators, groupId: criteriaGroupId })),
          );
        });
        return forkJoin(catalogues$) as unknown as Observable<DgnbIndicatorCatalogueWithSubCollections[]>;
      }));
  }

  /**
   * Retrieves and processes filtered indicators with calculated sums from Firestore.
   *
   * This function generates query conditions based on the project, constructs the path to the indicators collection,
   * and retrieves the data from Firestore. It then filters the indicators based on project properties, calculates
   * additional or variable points, generates an indicator tree, calculates sums, and returns a sorted list of indicators.
   *
   * @param {Project} project - The project object containing project details.
   * @param {string} scenarioId - The ID of the scenario.
   * @param {string} subject - The subject of the indicators.
   * @param {string} criteriaGroupId - The ID of the criteria group.
   * @param {string} catalogueId - The ID of the catalogue.
   * @param {ScenarioCollectionType} collectionType - The type of scenario collection.
   * @returns {Observable<DgnbIndicatorX[]>} An observable that emits the processed list of indicators.
   */
  private getFilteredIndicatorsWithSums(project: Project,
                                        scenarioId: string,
                                        subject: string,
                                        criteriaGroupId: string,
                                        catalogueId: string,
                                        collectionType: ScenarioCollectionType)
    : Observable<DgnbIndicatorX[]> {
    const queryConditions: QueryCondition[] = generateProjectIndicatorQueryConditions(project);
    const path: string = `projects/${project.id}/${collectionType}/${scenarioId}/${subject}/${criteriaGroupId}/catalogues/${catalogueId}/indicators`;
    return this._firebaseService.getCollectionData<PreCheckIndicator>(
      path,
      queryConditions,
      null,
    ).pipe(
      map(res => {
        if (!res.data) return [];
        const _filteredIndicators: PreCheckIndicator[] = filterIndicatorsByProjectProperties(res.data, project);
        const treeNodes: DgnbIndicatorTreeItem[] = generateIndicatorTree(_filteredIndicators, this.allBonusesMapSig());
        const treeNodesWithClpSums: DgnbIndicatorTreeItem[] = generateClpSumsFromIndicatorTree(treeNodes);
        return sortByKey(getIndicatorTreeItemsAsFlatIndicatorList(treeNodesWithClpSums), 'number');
      }),
    );
  }

  /**
   * Generates a map of project mixed usage profiles.
   * @param {MixedUsageProfile[]} projectMixedUsageProfiles - Array of project mixed usage profiles.
   * @returns {Map<string, Partial<MixedUsageProfile>>} A map where keys are usage profile names and values are partial mixed usage profiles.
   */
  generateAndRetrieveProjectMixedUsageProfileMap(
    projectMixedUsageProfiles: MixedUsageProfile[],
  ):  Map<string, Partial<MixedUsageProfile>> {
    // Calculate the sum of adgnb values in projectMixedUsageProfiles where adgnb is defined
    const aDgnbSum: number = projectMixedUsageProfiles
      .filter(o => o.adgnb)
      .map(o => o.adgnb)
      .reduce((pv, cv) => pv! + cv!, 0) || 0;

    // Initialize the map with a sum entry
    const projectMixedUsageProfilesMap: Map<string, Partial<MixedUsageProfile>> = new Map([
      ['sum', { adgnb: aDgnbSum }],
    ]);

    // Iterate over the project mixed usage profiles and add them to the map
    projectMixedUsageProfiles
      .forEach(mup => {
        projectMixedUsageProfilesMap.set(mup.usageProfile, mup);
      });

    return projectMixedUsageProfilesMap;
  }

  /**
   * Generates and retrieves a map of subject catalogue values.
   *
   * This function processes the scenario data to generate a map where each key is a subject,
   * and each value is an array of indicator catalogue values associated with that subject.
   * It calculates various sums (preCheck, assessment, audit) for each catalogue based on usage profiles.
   *
   * @param {ScenarioWithSubCollections} scenarioData - The scenario data containing subject groups and their sub-collections.
   * @returns {SubjectIndicatorCatalogueValuesMap} A map where keys are subjects and values are arrays of indicator catalogue values.
   */
  generateAndGetSubjectCatalogueValuesMap(scenarioData: ScenarioWithSubCollections): SubjectIndicatorCatalogueValuesMap {
    const subjectCatalogueValuesMap: SubjectIndicatorCatalogueValuesMap = new Map();
    const subjectGroups: Map<string, DgnbCriteriaGroupWithSubCollections[]> | undefined = scenarioData.subjectGroups;
    if (!subjectGroups?.size) return new Map();
    for (const [subject, groups] of subjectGroups) {
      let catalogueValues: IndicatorCatalogueValues[] = [];
      for (const group of groups) {
        if (!group.catalogues?.length) continue;
        const groupCatalogueValues: IndicatorCatalogueValues[] = group.catalogues.map((catalogue) => {
          const catalogueIndicators: DgnbIndicatorX[] = catalogue.indicators || [];
          const catalogueMaxClp = getCatalogueMaxClp(
            catalogueIndicators as PreCheckIndicator[], catalogue.maxClp,
          );

          const sumDataByUsageProfile: CatalogueSumDataByUsageProfile[] = getCatalogueClpSumsByUsageProfile(
            catalogueIndicators,
            catalogueMaxClp,
            this.allUsageProfilesMapSig(),
            this.projectMixedUsageProfileMapSig(),
          );

          const preCheckSum = sumDataByUsageProfile.length === 1
            ? sumDataByUsageProfile[0].preCheckCatalogueSum
            : sumDataByUsageProfile
              .map(o => o.preCheckCatalogueSum * o.adgnbValue / 100)
              .reduce((a, v) => a + v, 0);

          const assessmentSum = sumDataByUsageProfile.length === 1
            ? sumDataByUsageProfile[0].assessmentCatalogueSum
            : sumDataByUsageProfile
              .map(o => o.assessmentCatalogueSum * o.adgnbValue / 100)
              .reduce((a, v) => a + v, 0);

          const auditSum = sumDataByUsageProfile.length === 1
            ? sumDataByUsageProfile[0].auditCatalogueSum
            : sumDataByUsageProfile
              .map(o => o.auditCatalogueSum * o.adgnbValue / 100)
              .reduce((a, v) => a + v, 0);

          return {
            id: catalogue.id,
            indicatorCatalogueId: catalogue.id,
            indicatorCatalogueName: catalogue.name,
            criteriaGroupId: group.id,
            maxClp: catalogueMaxClp,
            preCheckClp: preCheckSum,
            assessmentClp: assessmentSum,
            auditClp: auditSum,
            sumDataByUsageProfile,
          };
        });
        catalogueValues = catalogueValues.concat(groupCatalogueValues);
      }
      subjectCatalogueValuesMap.set(subject, catalogueValues);
    }
    return subjectCatalogueValuesMap;
  }

  /**
   * Calculates the total costs of actions from the scenario data.
   *
   * This function extracts flat indicators from the provided scenario data,
   * filters the indicators to include only those with data, and then maps the actions
   * from these indicators. It sums up the costs of all actions and returns the total.
   *
   * @param {ScenarioWithSubCollections} scenarioData - The scenario data containing subject groups and their sub-collections.
   * @param {string} subject - A subject to filter for.
   * @returns {number} The total costs of actions.
   */
  generateSubjectCosts(scenarioData: ScenarioWithSubCollections, subject?: string): number {
    const indicators: DgnbIndicatorX[] | null = this.extractFlatIndicatorsFromScenarioData(scenarioData, subject);
    const dataIndicators: DgnbIndicatorX[] | undefined = indicators?.filter(indicator => indicator.data);
    const flatIndicatorActions: Action[] | undefined = dataIndicators?.map(indicator => indicator.data!.actions).flat();
    return flatIndicatorActions?.filter(action => action && action.costs)
      .reduce((acc, action) => acc + action.costs!, 0) || 0;
  }

  /**
   * Extracts flat catalogues from the scenario data.
   *
   * This function processes the scenario data to extract criteria groups and their associated catalogues.
   * It returns a list of catalogues with their sub-collections.
   *
   * @param {ScenarioWithSubCollections} scenarioData - The scenario data containing subject groups and their sub-collections.
   * @returns {DgnbIndicatorCatalogueWithSubCollections[] | null} The list of catalogues with their sub-collections, or null if no subject groups are found.
   */
  extractFlatCataloguesFromScenarioData(scenarioData: ScenarioWithSubCollections): DgnbIndicatorCatalogueWithSubCollections[] | null {
    if (!scenarioData?.subjectGroups) return null;

    // Extract criteria groups from the scenario data
    const criteriaGroups: DgnbCriteriaGroupWithSubCollections[] = Array.from(scenarioData.subjectGroups.values()).flatMap(group => group);

    // Extract catalogues from the criteria groups
    return criteriaGroups.filter(group => group.catalogues)
      .flatMap(group => group.catalogues) as DgnbIndicatorCatalogueWithSubCollections[];
  }

  /**
   * This method processes the scenario data to extract criteria groups, catalogues, and indicators,
   * and then generates the chart data using the `generateStatusChartDataFromIndicators` function.
   * @param scenarioData - The scenario data containing subject groups and their sub-collections.
   * @param subject - A subject to filter for
   * @private
   */
  extractFlatIndicatorsFromScenarioData(scenarioData: ScenarioWithSubCollections, subject?: string): DgnbIndicatorX[] | null {
    if (!scenarioData?.subjectGroups) return null;

    const filteredSubjectGroups: Map<string, DgnbCriteriaGroupWithSubCollections[]> = subject
      ? createFilteredMap(scenarioData.subjectGroups, [subject])
      : scenarioData.subjectGroups;

    const filteredScenarioData: ScenarioWithSubCollections = subject
      ? { ...scenarioData, subjectGroups: filteredSubjectGroups }
      : scenarioData;

    // Extract catalogues from the criteria groups
    const catalogues: DgnbIndicatorCatalogueWithSubCollections[] | null = this.extractFlatCataloguesFromScenarioData(filteredScenarioData);

    // Extract und return indicators from the catalogues
    return catalogues?.filter(catalogue => catalogue.indicators)
      .flatMap(catalogue => catalogue.indicators) as DgnbIndicatorX[] | null;
  }

  /**
   * Sets the subject indicator catalogue values map.
   *
   * This function updates the state with the provided map of subject catalogue values.
   *
   * @param {SubjectIndicatorCatalogueValuesMap} subjectCataloguesValuesMap - The map of subject catalogue values to set.
   */
  setSubjectIndicatorCatalogueValuesMap(subjectCataloguesValuesMap: SubjectIndicatorCatalogueValuesMap) {
    this.subjectIndicatorCatalogueValuesMap.set(subjectCataloguesValuesMap);
  }

  setProjectMixedUsageProfileMap(projectMixedUsageProfileMap: Map<string, Partial<MixedUsageProfile>>) {
    this.projectMixedUsageProfilesMap.set(projectMixedUsageProfileMap);
  }

  /**
   * Sets the usage profiles.
   * @param usageProfiles - Array of usage profiles to set.
   */
  setAllUsageProfiles(usageProfiles: UsageProfile[]) {
    this.allUsageProfiles.set(usageProfiles);
    this.allUsageProfilesMap.set(new Map(usageProfiles.map(o => {
      return [o.id, o.name as UsageProfileEnum];
    })));
  }

  /**
   * Sets the bonuses.
   * @param bonuses - Array of bonuses to set.
   */
  setAllBonuses(bonuses: Bonus[]) {
    this.allBonuses.set(bonuses);
    this.allBonusesMap.set(new Map(bonuses.map(o => {
      return [o.id, o.name as BonusEnum];
    })));
  }

  /**
   * Triggers a refetch of fulfillment data.
   */
  triggerFulfillmentDataRefetch() {
    this.fulfillmentDataRefetchTrigger.next();
  }
}
